import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App-bg">
        <header className="App-header">
          <h2>
            Data Diri
          </h2>
        </header>
        <div className="App-line">
          <p className="text">Nama</p>
          <p className="DataDiri-tab">: Muhammad Iqbal</p>
        </div>
        <div className="App-line">
          <p className="text">Tempat, Tgl Lahir</p>
          <p className="DataDiri-tab">: Jakarta, 29 Agustus 1996</p>
        </div>
        <div className="App-line">
          <p className="text">Jenis Kelamin</p>
          <p className="DataDiri-tab">: Laki-laki</p>
        </div>
        <div className="App-line">
          <p className="text">Kewarganegaraan</p>
          <p className="DataDiri-tab">: Indonesia</p>
        </div>
        <div className="App-line">
          <p className="text">Status</p>
          <p className="DataDiri-tab">: Belum Menikah</p>
        </div>
        <div className="App-line">
          <p className="text">Alamat Sekarang</p>
          <p className="DataDiri-tab">: Jln. Kiwi Blok GR 22 No. 27, Pondok Aren</p>
        </div>
        <div className="App-line">
          <p className="DataDiri-tab">Tangerang Selatan</p>
        </div>
        <div className="App-line">
          <p className="text">No HP</p>
          <p className="DataDiri-tab">: 082233233440</p>
        </div>
        <div className="App-line">
          <p className="text">Email</p>
          <p className="DataDiri-tab">: iqbal290896@gmail.com</p>
        </div>
        <br></br>
        <header className="App-header">
          <h2>
            Pendidikan
          </h2>
        </header>
        <div className="App-line">
          <p className="text">SMA Muhammadiyah 1 Nganjuk</p>
          <p className="right-text">2011 - 2014</p>
        </div>
        <div className="App-line">
          <p className="text">IPA</p>
        </div>
        <br></br>
        <header className="App-header">
          <h2>
            Pengalaman Kerja
          </h2>
        </header>
        <div className="App-line">
          <p className="text"><b>Magang</b> - Badan Pertanahan Nasional Sleman</p>
          <p className="right-text"> 2016 Des - 2017 Jan</p>
        </div>
        <div className="App-line">
          <p className="text">Sebagai <b>web developer</b>, membuat inventaris alat</p>
        </div>
        <div className="App-line">
          <p className="text">dalam bentuk website.</p>
        </div>
        <div className="App-line">
          <p className="text">HTML-CSS, PHP, MySQL</p>
        </div>
        <br></br>
        <div className="App-line">
          <p className="text"><b>Magang</b> - PT. Rayterton</p>
          <p className="right-text">2017 Mei - 2017 Juli</p>
        </div>
        <div className="App-line">
          <p className="text">Sebagai <b>oracle developer</b> mengerjakan website</p>
        </div>
        <div className="App-line">
          <p className="text">klien perusahaan baja.</p>
        </div>
        <div className="App-line">
          <p className="text">Oracle APEX, ORACLE 11g</p>
        </div>
        <br></br>
        <div className="App-line">
          <p className="text"><b>IT Support</b> - PT. Arkade Pratama Indonesia</p>
          <p className="right-text">2018 Jan - 2019 Jan</p>
        </div>
        <div className="App-line">
          <p className="text">Mengurus segala elektronik, komputer, software POS,</p>
        </div>
        <div className="App-line">
          <p className="text">hosting website, email, server sql, wifi mikrotik,</p>
        </div>
        <div className="App-line">
          <p className="text">sampai printer.</p>
        </div>
        <br></br>
        <div className="App-header">
          <h2>
            Skill
          </h2>
        </div>
        <div className="App-line">
          <p className="text"><b>Komputer Dasar</b></p>
        </div>
        <div className="App-line">
          <p className="text">Microsoft Office, Linux (Debian, RHEL, dan Arch based)</p>
        </div>
        <br></br>
        <div className="App-line">
          <p className="text"><b>Linux Administration</b></p>
        </div>
        <div className="App-line">
          <p className="text">NGINX, NodeJS, OpenVPN, Stack untuk keperluan server.</p>
        </div>
        <div className="App-line">
          <p className="text">(Digital Ocean VPS) serta membuat simple API</p>
        </div>
        <br></br>
        <div className="App-line">
          <p className="text"><b>Programming Languange</b></p>
        </div>
        <div className="App-line">
          <p className="text">C++, PHP(code igniter), Javascript(express),</p>
        </div>
        <div className="App-line">
          <p className="text">python(flask), HTML-CSS</p>
        </div>
        <br></br>
        <div className="App-line">
          <p className="text"><b>Database</b></p>
        </div>
        <div className="App-line">
          <p className="text">MySQL, ORACLE 11g, MongoDB</p>
        </div>
        <br></br>
        <footer className="App-footer">
          <img src={logo} className="App-logo" alt="logo" />
          <p>Proudly using <b>react</b></p>
        </footer>
    </div>


    /*<div className="App">
      <header className="App-header">
        <h1>
          Muhammad Iqbal
        </h1>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>*/
  );
}

export default App;
